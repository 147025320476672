import { Component, WritableSignal, inject } from '@angular/core';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { IResource } from '@views/features/resources/interfaces/iresource';
import { ActivatedRoute, Router } from '@angular/router';
import { iif, take, tap } from 'rxjs';
import { ResourceService } from '../../services/resource.service';
import { AuthService } from '@core/services/auth.service';
import { ILoginResponse } from '@core/interfaces/ilogin-response';
import { Role } from '@core/enums/roles';
import { IImpactVideo } from '../../interfaces/iimpact-video';
import { Resources } from '../../enums/resources';
import { HelperService, extractDomainFromUrl } from '@core/services/helper.service';

@Component({
  selector: 'prism-resource-view',
  templateUrl: './resource-view.component.html',
  styleUrl: './resource-view.component.scss'
})
export class ResourceViewComponent {
  private sanitizer = inject(DomSanitizer);
  private route = inject(ActivatedRoute);
  private resourceService = inject(ResourceService);
  private authService = inject(AuthService);
  private router = inject(Router);

  currentUser: WritableSignal<ILoginResponse | null> = this.authService.currentUserInfo;
  resourceId: Number = 0;
  isEditable: boolean = false;
  isExternal: boolean = false;
  isImpactVideo: boolean = false;
  private resourceContainer = '';
  sasToken: string = '';
  sanitizedSasToken!: SafeUrl;
  imgUrl: string = '';
  resource!: any;
  blobImgUrl: SafeResourceUrl = '';

  constructor() {
    this.route.url.subscribe(urlSegments => {
      const path = urlSegments[1].path; 
      this.isExternal = path === Resources.External;
      this.isImpactVideo = path === Resources.ImpactVideo;
      this.resourceContainer = this.isExternal ? 'resources' : 'impact-videos';
    });
  }

  ngOnInit(): void {
    const id = Number(this.route.snapshot.paramMap.get("id"));
    iif (
      () => this.isExternal,
      this.resourceService.getResource(id),
      this.resourceService.getImpactVideo(id)
    ).pipe(take(1)).subscribe((res: IResource | IImpactVideo) => {
      if (res) {
        this.resource = res;
        this.resourceId = res.id;
        this.imgUrl = res.imageLink;
        this.isEditable = this.currentUser()?.roleId === Role.SystemAdmin;
        this.getSasToken();
      }
    })
  }

  getSasToken(): void {
    this.authService
      .getStorageSasToken(this.resourceContainer)
      .pipe(
        take(1),
        tap((res: any) => {
          this.sasToken = res.value;
          this.generateUrlWithSasToken(this.imgUrl);
        })
      )
      .subscribe();
  }

  generateUrlWithSasToken(blobUrl?: string) {
    const urlWithSasToken = `${blobUrl}${this.sasToken}`;
    this.blobImgUrl = this.sanitizer.bypassSecurityTrustResourceUrl(urlWithSasToken);
  }

  goToResource(): void {
    window.open(this.resource.buttonLink, "_blank");
  }

  goToResourceForm(): void {
    let resourceType = '';
    resourceType = this.isExternal ? 'external' : 'impact-video';
    this.router.navigateByUrl(`resources/${resourceType}/form/${this.resourceId}`);
  }

  getUploadImage(image: string) {
    return this.sanitizer.bypassSecurityTrustStyle(`url(${image})`);
  }

  extractDomain(url: string): string {
    return extractDomainFromUrl(url);
  }
}
