import { Component, Input } from '@angular/core';

@Component({
  selector: 'prism-btn',
  standalone: false,
  templateUrl: './btn.component.html',
  styleUrl: './btn.component.scss'
})
export class BtnComponent {
  @Input() btnType: string = 'submit';
  @Input() btnClass: string = 'btn-purple';
  @Input() disabled: boolean = false;
  constructor() { }

}
