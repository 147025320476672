<div class="p-6 px-0 w-full h-full flex flex-col">
  <div class="flex justify-end pt-0 pb-3 lg:pt-6">
    <div class="relative inline-flex self-center">
      <div class="flex flex-wrap items-center justify-between">
        <nz-pagination
          [nzPageIndex]="pageIndex"
          [nzTotal]="totalData"
          [nzPageSize]="pageSize"
          (nzPageIndexChange)="handlePageIndexChange($event)"
          [nzItemRender]="renderItemTemplate"
        ></nz-pagination>
        <prism-select-page-size (pageSize)="handlePageSizeChange($event)" class="ml-auto pl-3 w-30 md:w-40 lg:w-52"></prism-select-page-size>
      </div>      
      
    <ng-template #renderItemTemplate let-type let-page="page">
      @switch (type) { @case ('page') {
      <a>{{ page }}</a>
      } @case ('prev') {
      <a>Previous</a>
      } @case ('next') {
      <a>Next</a>
      } }
    </ng-template>
    </div>
  </div>
  <div class="flex-grow relative overflow-x-auto">
    <ng-container *ngIf="tableData && tableData.length > 0">
      <table class="mt-4 w-full min-w-max table-auto text-left px-10">
        <thead>
          <tr>
          @for (col of columns; track col) {
            <td class="pr-4">
              <p class="text-sm font-semibold bg-none text-dark-purple flex items-center justify-between gap-2">
                {{colHeaders[col] }}
              </p>
            </td>
          } 
          @if(isDelete || isEdit) {
            <td class="pr-4">
              <p class="text-sm font-semibold bg-none text-dark-purple flex items-center justify-between gap-2"></p>
            </td>
          }
        </tr>
        </thead>
        <tbody>
          @for (data of tableData; track $index) {
            <tr class="bg-light-purple border-y-8 border-white m-5">
              @for (col of columns; track col) {
                <td class="p-2" [ngClass]="{ 'rounded-l-2xl': $index === 0 }">{{ data[col] }}</td>
              }
                <td class="mr-2 rounded-r-2xl">
                @for (item of actions; track $index) {
                  @if (item.isActive) {
                    <button class=" pl-2 text-2xl" [ariaLabel]="item.type" (click)="item.handler(data, item.type)">
                      <i [class]="item.icon"></i>
                    </button>
                  }
                }
                </td>
            </tr>
          } 
        </tbody>
      </table>
    </ng-container>
    <!-- Show empty table -->
    <ng-container *ngIf="tableData && tableData.length < 1"> 
      <nz-table class="mt-4 w-full min-w-max table-auto text-left px-10" [nzData]="tableData">
        <tbody>
        </tbody>
      </nz-table>
    </ng-container>
  </div>
  <div class="flex justify-end pt-3 lg:pt-6">
    <div class="relative inline-flex self-center">
      <div class="flex flex-wrap items-center justify-between">
        <nz-pagination
          [nzPageIndex]="pageIndex"
          [nzTotal]="totalData"
          [nzPageSize]="pageSize"
          (nzPageIndexChange)="handlePageIndexChange($event)"
          [nzItemRender]="renderItemTemplate"
        ></nz-pagination>
      </div>
    </div>
  </div>
</div>
