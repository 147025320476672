import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateFormat',
  standalone: true
})
export class DateFormatPipe implements PipeTransform {

  transform(utcDate?: string, format: string = 'longDateTime', timeZone: string = Intl.DateTimeFormat().resolvedOptions().timeZone): string {
    if (!utcDate) return '';

    const utcDateTime = new Date(utcDate);
    const offset = utcDateTime.getTimezoneOffset();
    utcDateTime.setMinutes(utcDateTime.getMinutes() - offset);

    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
      timeZone,
    };
    const dateFormatter = new Intl.DateTimeFormat('en-US', options);

    if (!isNaN(utcDateTime.getTime())) {
      return dateFormatter.format(utcDateTime);
    } else {
      return 'Invalid Date';
    }
  }
}
