import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable, WritableSignal, signal } from '@angular/core';
import { catchError, finalize, Observable, of, tap } from 'rxjs';
import { ToastService } from './toast.service';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({
  providedIn: 'root'
})


export class DataService {
  isLoading: WritableSignal<boolean> = signal(false);
  constructor(private http: HttpClient, private toast: ToastService,

    ) { }

  get(path: string,
    message?: string,
    isLoading: boolean = true
    ): Observable<any> {
      this.isLoading.set(isLoading);
    return this.http.get(path, httpOptions)
      .pipe(
        tap(() => this.handleSuccess(message)),
        catchError((err: any) => this.handleError(err)),
        finalize(()=>  this.isLoading.set(false))
    )
  }

    post(path: string, payload: any, message?: string): Observable<any> {
      this.isLoading.set(true);
      return this.http.post(path, payload)
        .pipe(
          tap(() => this.handleSuccess(message)),
          catchError((err: any) => this.handleError(err)),
          finalize(()=> this.isLoading.set(false))
      );
    }

    postWithOptions(path: string, payload: any, options: any, message?: string): Observable<any> {
      this.isLoading.set(true);
      return this.http.post(path, payload, options)
        .pipe(
          tap(() => this.handleSuccess(message)),
          catchError((err: any) => this.handleError(err)),
          finalize(()=> this.isLoading.set(false))
      );
    }

    delete(path: string, message?: string): Observable<any> {
      this.isLoading.set(true);
      return this.http.delete(path, httpOptions)
        .pipe(
          tap(() => this.handleSuccess(message)),
          catchError((err: any) => this.handleError(err)),
          finalize(()=> this.isLoading.set(false))
      );
    }

    patch(path: string, payload: any, message?: string): Observable<any> {
      this.isLoading.set(true);
      return this.http.patch(path, payload)
        .pipe(
          tap(() => this.handleSuccess(message)),
          catchError((err: any) => this.handleError(err)),
          finalize(()=> this.isLoading.set(false))
      );
    }

    put(path: string, payload: unknown, message?: string): Observable<any> {
      this.isLoading.set(true);
      return this.http.put(path, payload)
        .pipe(
          tap(() => this.handleSuccess(message)),
          catchError((err: any) => this.handleError(err)),
          finalize(()=> this.isLoading.set(false))
      );
    }

    private handleSuccess(message?: string) {
      if (message) {
        this.toast.success(message)
      }
    }

    private handleError(err: HttpErrorResponse) {
      let message = 'Something went wrong.';
      if (err.error) {
        message = (err.error && typeof err.error === 'string') ? err.error : err.error.ErrorMessage ? err.error.ErrorMessage : err.message;
      }
      this.toast.error(message)
      return of(false);
    }
}
