<div class="flex flex-col text-base">
  <label 
    [attr.aria-label]="label ? label : 'label'" 
    [class]="labelClass" 
    [ngClass]="{ 'required': required }">
    {{ label }}
  </label>
  <nz-select
    prismAddAriaLabel
    [querySelector]="ariaSelector"
    [ariaLabel]="ariaLabel"
    [formControl]="control"
    class="w-full px-1 py-1 border border-dark-purple rounded-lg bg-white text-base placeholder-dark-gray"
    [nzPlaceHolder]="placeHolder"
    nzBorderless
    [nzDisabled]="readOnly"
    nzShowSearch
    nzServerSearch
    (nzOnSearch)="searchOptions($event)"
    (nzOnSelect)="selectOption($event)"
  >
    <nz-option *ngIf="allowDeselect" [nzValue]="null" nzLabel="Select an option"></nz-option>
    <nz-option-group *ngFor="let group of optionGroups" [nzLabel]="group.label">
      <nz-option
        *ngFor="let option of group.options"
        [nzValue]="option.id"
        [nzLabel]="option.name"
      >
      </nz-option>
    </nz-option-group>
  </nz-select>
</div>