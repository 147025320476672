import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { IGrades } from '@core/interfaces/ilookup';
import { LookupService } from '@core/services/lookup.service';
import { tap } from 'rxjs';

@Component({
  selector: 'prism-select-grade',
  templateUrl: './select-grade.component.html',
  styleUrl: './select-grade.component.scss'
})
export class SelectGradeComponent {
  @Output() grade = new EventEmitter<any>();
  @Input() selectedGrade?: number | null;
  @Input() control!: FormControl | any;
  @Input() label: string = '';
  @Input() required: boolean = false;
  @Input() readOnly?: boolean = false;
  @Input() labelClass: string = '';
  options: IGrades[] = [];

  constructor(private lookupService: LookupService) {}

  ngOnInit(): void {
    this.getGrades();
  }

  getGrades(): void {
    this.lookupService.getGrades()
    .pipe(
      tap((res: IGrades[]) => this.options = res)
    )
    .subscribe();
  }

  emitgrade(role: any): void {
    this.grade.emit(role);
  }
}
