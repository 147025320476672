import { Component, WritableSignal, inject } from '@angular/core';
import { Router } from '@angular/router';
import { ResourceService } from '../../services/resource.service';
import { take, tap } from 'rxjs';
import { AuthService } from '@core/services/auth.service';
import { IImpactVideoResponse } from '../../interfaces/iimpact-video';
import { Role } from '@core/enums/roles';
import { ILoginResponse } from '@core/interfaces/ilogin-response';

@Component({
  selector: 'prism-impact-video',
  templateUrl: './impact-video.component.html',
  styleUrl: './impact-video.component.scss'
})
export class ImpactVideoComponent {
  private router = inject(Router);
  private resourceService = inject(ResourceService);
  private authService = inject(AuthService);
  private resourceContainer = 'impact-videos';
  currentUser: WritableSignal<ILoginResponse | null> = this.authService.currentUserInfo;
  resources: IImpactVideoResponse[] = [];
  canAdd: boolean = false;
  sasToken: string = '';

  constructor() { this.getSasToken(); }

  ngOnInit(): void {
    this.canAdd = this.currentUser()?.roleId === Role.SystemAdmin;
    this.resourceService.getAllImpactVideos().pipe(
      take(1),
      tap((res: IImpactVideoResponse[]) => {
        this.resources = res;
      })).subscribe();
  }

  getSasToken(): void {
    this.authService.getStorageSasToken(this.resourceContainer).pipe(
      take(1),
      tap((res: any) => (this.sasToken = res.value))
    ).subscribe();
  }
  
    viewImpactVideo(id: number): void {
      this.router.navigateByUrl(`resources/impact-video/view/${id}`);
    }
    
    goToResourcesForm(): void {
      this.router.navigateByUrl('resources/impact-video/form');
    }
}