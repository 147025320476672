import { Component } from '@angular/core';

@Component({
  selector: 'prism-footer',
  standalone: false,
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss'
})
export class FooterComponent {

}
