<div class="overlay" *ngIf="openSideMenu"></div>
<div id="header" class="bg-light-purple w-full px-3">
  <div class="py-3 px-8 md:px-16 mx-10 lg:block lg:mx-22 xl:mx-auto">
    <div class="grid grid-cols-4 gap-4 items-center">
      <a class="col-span-1 flex items-center space-x-2" href="/home">
        <img class="w-28 min-w-20" [src]="logoUrl" alt="Prism Logo">
      </a>
      <div class="col-span-3 flex justify-end items-center cursor-pointer">
        @if (!this.currentUser()) {
          <div class="hidden lg:block">
            <img [src]="iconImgUrl" alt="User Icon" (click)="toggleModal(HeaderModalType.Login)">
          </div>
        }
        @else {
          <div class="hidden lg:block">
            <div nz-popover [nzPopoverTitle]="currentUser()?.fullName"
              [nzPopoverContent]="userInfoTemplate"
              nzPopoverPlacement="bottomRight" class="flex items-center">
              <img [src]="iconImgUrl" alt="User Icon">
              <i class="uil uil-angle-down text-xl text-dark-purple"></i>
            </div>
          </div>
        }
        <div class="lg:hidden">
          @if (!openSideMenu) {
            <i class="text-4xl uil uil-bars cursor-pointer" (click)="click()"></i>
          }
          @else {
            <i class="text-2xl uil uil-multiply relative z-[2000]" (click)="click()"></i>
          }
        </div>
      </div>
    </div>
  </div>
</div>
<prism-navbar [openSideMenu]="openSideMenu" (openLoginModal)="toggleModal(HeaderModalType.Login)"></prism-navbar>
<nz-modal [(nzVisible)]="isLoginVisible" 
  nzTitle="Log In" 
  (nzOnCancel)="handleCancel()" 
  [nzFooter]="null" 
  [nzBodyStyle]="{'padding':'24px 24px 10px'}">
  <ng-container *nzModalContent>
    <form [formGroup]="loginForm" class="grid gap-4" (ngSubmit)="submit()">
        <prism-input
          [control]="emailAddress"
          label="Email"
          placeHolder="Email address"
          [required]="true"
          [inputType]="InputType.Email">
        </prism-input>
        <prism-input
          [control]="password"
          label="Password"
          placeHolder="Password"
          [required]="true"
          [inputType]="InputType.Password">
        </prism-input>
        <hr class="-mx-6">
        <div class="flex items-center">
          <prism-btn [disabled]="!loginForm.valid">Log in</prism-btn>
          <p class="ml-4"><a (click)="toggleModal(HeaderModalType.ForgotPw)">Forgot password?</a></p>
        </div>
    </form>
  </ng-container>
</nz-modal>
<nz-modal [(nzVisible)]="isForgotPwVisible" nzTitle="Forgot Password" (nzOnCancel)="handleCancel()">
  <ng-container *nzModalContent>
    <h1>Enter your email address to reset your password.</h1>
    <form [formGroup]="forgotPwForm" class="grid gap-4">
      <prism-input
        [control]="forgotPwEmailAddress"
        placeHolder="Email address"
        [inputType]="InputType.Email">
      </prism-input>
    </form>
  </ng-container>
  <div *nzModalFooter class="flex items-center">
    <prism-btn [disabled]="!forgotPwForm.valid || isProcessing" (click)="submitForgotPw()">Forgot Password</prism-btn>
  </div>
</nz-modal>
<nz-modal [(nzVisible)]="successModal" nzTitle="Forgot Password" nzOkText="Ok" nzCancelText="Cancel" (nzOnCancel)="handleCancel()" [nzFooter]=null>
  <ng-container *nzModalContent>
    <h1>Please check your inbox shortly for an email to reset your password.
      If you do not receive an email in your inbox after approximately 10-15 minutes,
      check your spam/junk mail folder. Thank you.</h1>
  </ng-container>
</nz-modal>
<ng-template #userInfoTemplate>
  <div>
    <div class="mx-2">
      <span class="italic">{{ currentUser()?.roleName }}</span>
    </div>
    @if (currentUser()?.districtId) {
      <div class="mx-2">
        <span class="">{{ currentUser()?.districtName }}</span>
      </div>
    }
    <hr>
    <div class="font-bold text-dark-gray">
      <a class="mx-2" href="/course-history">Courses History</a>
      <hr>
      <a class="mx-2" href="/account-settings">Account Settings</a>
      <hr>
      <a class="mx-2" (click)="logout()">
        <i class="uil uil-signout text-lg text-dark-gray"></i>
        Log Out
      </a>
    </div>
  </div>
</ng-template>

