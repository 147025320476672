<div class="flex flex-col text-base">
    <label 
      [attr.aria-label]="label ? label : 'label'" 
      [ngClass]="{ 'required' : required }" 
      [class]="labelColor">
      {{label}}
    </label>
    @switch (inputType) {
      @case (InputType.Text) {
        <div class="col-span-2 relative">
          <div class="flex flex-row items-center">
            <input type="text"
                  [placeholder]="placeHolder"
                  [attr.aria-label]="label ? label : 'label'"
                  [formControl]="control"
                  [readonly]="readOnly"
                  class="w-full px-4 py-2 border border-dark-purple rounded-lg"
                  [maxlength]="maxLength"
                  [ngClass]="{ 'border-red': control.invalid && (control.dirty || control.touched) }"
                  (keyup.enter)="searchInput(control.value)">
            @if (hasSearch) {
              <i class="uil uil-search absolute text-dark-purple right-0 mr-3 text-xl cursor-pointer" (click)="searchInput(control.value)"></i>
            }
          </div>
        </div>
        @if (control.invalid  && (control.dirty || control.touched)) {
          <p class="text-sm text-red pt-1">{{label}} is invalid.</p>
        }
      }
      @case (InputType.Number) {
        <div class="col-span-2 relative">
          <div class="flex flex-row items-center">
            <input type="number"
                  [placeholder]="placeHolder"
                  [attr.aria-label]="label ? label : 'label'"
                  [formControl]="control"
                  [readonly]="readOnly"
                  min="0"
                  class="w-full px-4 py-2 border border-dark-purple rounded-lg"
                  maxlength="20"
                  [ngClass]="{ 'border-red': control.invalid && (control.dirty || control.touched) }"
                  (keyup.enter)="searchInput(control.value)">
            @if (hasSearch) {
              <i class="uil uil-search absolute text-dark-purple right-0 mr-3 text-xl cursor-pointer" (click)="searchInput(control.value)"></i>
            }
          </div>
        </div>
        @if (control.invalid  && (control.dirty || control.touched)) {
          <p class="text-sm text-red pt-1">{{label}} is invalid.</p>
        }
      }
      @case (InputType.Email) {
        <input
          [placeholder]="placeHolder"
          [id]="label"
          [attr.aria-label]="label ? label : 'label'"
          type="email"
          email="true"
          [readonly]="readOnly"
          [formControl]="control"
          class="w-full px-4 py-2 border border-dark-purple rounded-lg"
          maxlength="50"
          [ngClass]="{ 'border-red': control?.invalid && (control?.dirty || control?.touched) }">
          @if (control.errors?.invalidEmail) {
            <p class="text-sm text-red pt-1">Email address unavailable.</p>
          } 
          @else if (control?.invalid  && (control?.dirty || control?.touched)) {
            <p class="text-sm text-red pt-1">{{ 'Email address is ' + 
              (control.errors?.required ? 'required.' : 'invalid.') }}</p>
          }
      }
      @case (InputType.Password) {
        <input
          [placeholder]="placeHolder"
          [id]="label"
          [attr.aria-label]="label ? label : 'label'"
          type="password"
          [formControl]="control"
          class="w-full px-4 py-2 border border-dark-purple rounded-lg"
          [maxlength]="maxLength"
          [ngClass]="{ 'border-red': control.invalid && (control.dirty || control.touched) }">
      @if (control.invalid  && (control.dirty || control.touched)) {
        <p class="text-sm text-red pt-1">Password is required.</p>
        }
      }
      @case (InputType.Textarea) {
        <textarea
          [placeholder]="placeHolder"
          [id]="label"
          [attr.aria-label]="label ? label : 'label'"
          [formControl]="control"
          rows="6"
          class="w-full px-4 py-2 border border-dark-purple rounded-lg"
          [maxlength]="maxLength"
          [ngClass]="{ 'border-red': control.invalid && (control.dirty || control.touched) && !control.pristine}"></textarea>
        @if (control.invalid  && (control.dirty || control.touched)  && !control.pristine) {
            <p class="text-sm text-red pt-1">{{label}} is invalid.</p>
          }
      }
      @case (InputType.File) {
        <div class="grid grid-cols-2 gap-x-4">
          <input type="text"
          [placeholder]="placeHolder"
          [attr.aria-label]="label ? label : 'label'"
          [formControl]="control"
          [readonly]="readOnly"
          class="w-full px-4 py-2 border border-dark-purple rounded-lg"
          [ngClass]="{ 'border-red': control.invalid && (control.dirty || control.touched) }">
          <div class="pt-2">
            <input
              type="file"
              name="file"
              id="file-input-{{identifier}}"
              (change)="file($event)"
              [attr.aria-label]="label ? label : 'label'"
              [accept]="acceptFileType"
              hidden
              [ngClass]="{ 'border-red': control.invalid && (control.dirty || control.touched) }"/>
            <label class="btn-purple p-3 px-8 font-normal cursor-pointer" for="file-input-{{identifier}}">Browse</label>
          </div>
        </div>
        @if (control.invalid  && (control.dirty || control.touched)) {
            <p class="text-sm text-red pt-1">{{label}} is invalid.</p>
          }
      }
      @case (InputType.RichTextEditor) {
        <quill-editor [formControl]="control" 
          (onContentChanged)="onContentChanged($event)" 
          [styles]="{height: '200px'}" 
          [maxLength]="2000" 
          [modules]="quillConfiguration"
          [placeholder]="placeHolder">
        </quill-editor>
        <div id="toolbar">
          <select class="ql-size" aria-label="font size">
            <option value="small"></option>
            <option selected></option>
            <option value="large"></option>
            <option value="huge"></option>
          </select>
          <button class="ql-bold" aria-label="bold"></button>
          <button class="ql-underline" aria-label="underline"></button>
          <select class="ql-color" aria-label="color"></select>
          <select class="ql-background" aria-label="background"></select>
          <button class="ql-list" value="ordered" aria-label="ordered list"></button>
          <button class="ql-list" value="bullet" aria-label="bullet list"></button>
          <button class="ql-link" aria-label="link"></button>
        </div>
        <div id="editor"></div>
        @if (control.invalid  && (control.dirty || control.touched)) {
          <p class="text-sm text-red pt-1">{{label}} is invalid.</p>
        }
      }
    }
</div>
