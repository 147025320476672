import { Component, Signal } from '@angular/core';
import { DataService } from '@core/services/data.service';

@Component({
  selector: 'prism-spinner',
  standalone: false,
  templateUrl: './spinner.component.html',
  styleUrl: './spinner.component.scss'
})
export class SpinnerComponent {
isLoading: Signal<boolean> = this.dataService.isLoading;

constructor(private dataService: DataService) {}

}
