<prism-select 
  [selectOptions]="options"
  [label]="label"
  placeHolder="Select Grade"
  [control]="control"
  [required]="required"
  [readOnly]="readOnly"
  [allowDeselect]="true"
  [labelClass]="labelClass"
  (onSelected)="emitgrade($event)">
</prism-select>
