import { Component, WritableSignal, inject } from '@angular/core';
import { Router } from '@angular/router';
import { ResourceService } from '../../services/resource.service';
import { IResource } from '../../interfaces/iresource';
import { take, tap } from 'rxjs';
import { AuthService } from '@core/services/auth.service';
import { ILoginResponse } from '@core/interfaces/ilogin-response';
import { Role } from '@core/enums/roles';

@Component({
  selector: 'prism-external-resources',
  templateUrl: './external-resources.component.html',
  styleUrl: './external-resources.component.scss'
})
export class ExternalResourcesComponent {
  private router = inject(Router);
  private resourceService = inject(ResourceService);
  private authService = inject(AuthService);
  currentUser: WritableSignal<ILoginResponse | null> = this.authService.currentUserInfo;
  resources: IResource[] = [];
  private resourceContainer = 'resources';
  sasToken: string = '';
  canAdd: boolean = false;
  constructor() { this.getSasToken(); }

  ngOnInit(): void {
    this.canAdd = this.currentUser()?.roleId === Role.SystemAdmin;
    this.resourceService.getResources().pipe(
      take(1),
      tap((res: IResource[]) => {
        this.resources = res;
      })
    ).subscribe();
  }

  viewResource(id: number): void {
    this.router.navigateByUrl(`resources/external/view/${id}`);
  }

  goToResourcesForm(): void {
    this.router.navigateByUrl('/resources/external/form');
  }

  getSasToken(): void {
    this.authService
      .getStorageSasToken(this.resourceContainer).pipe(
        take(1),
        tap((res: any) => (this.sasToken = res.value))
      ).subscribe();
  }
}
