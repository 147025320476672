import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { InputType } from '@core/enums/input-type';
import { ToastService } from '@core/services/toast.service';
import { take, tap } from 'rxjs';
import { CoursesService } from '../services/courses.service';
import { ICourseAttachment } from '../interface/icourse-attachment';
import { FileInput } from '@core/enums/file-input';

@Component({
  selector: 'prism-course-attachment',
  templateUrl: './course-attachment.component.html',
  styleUrl: './course-attachment.component.scss'
})
export class CourseAttachmentComponent {
  private fb = inject(FormBuilder);
  private courseService = inject(CoursesService);
  private toastService = inject(ToastService);
  private route = inject(ActivatedRoute);
  @Input() courseId: number = 0;
  @Input() isEdit = false;
  @Output() closeAttachment = new EventEmitter();
  @Output() updateAttachments = new EventEmitter();

  attachmentForm: FormGroup | any;
  InputType = InputType;
  selectedFile: any;
  attachment: any = {};
  formData: FormData | null = null;
  attachmentSasToken: string = '';
  attachments: ICourseAttachment[] = [];

  constructor() {
    this.courseId = Number(this.route.snapshot.paramMap.get("id"));
  }

  ngOnInit(): void {
    this.initForm();
  }

  initForm(): void {
    this.attachmentForm = this.fb.group({
      id: [0],
      courseId: [this.courseId, Validators.required],
      fileName: [null, Validators.required],
    });
  }

  public fileChange(event: any) {
    if (event.id === FileInput.Attachment) {
      const fileList: FileList = event.file.target.files;
      if (fileList.length > 0) {
        const chosenFile: File = fileList[0];
        this.selectedFile = chosenFile;
        this.attachmentForm.get('fileName').markAsDirty();
        this.attachmentForm.patchValue({
          fileName: chosenFile.name,
        });
      }
    }
	}

  submitResource(): void {
    if (this.attachmentForm.invalid) {
      this.toastService.error('Please fill out all required fields');
      return;
    }

    if (this.isEdit && this.attachmentForm.pristine) {
      this.toastService.error('No changes made');
      return;
    }

    Object.assign(this.attachment, this.attachmentForm.value);
    this.formData = new FormData();

    if (this.selectedFile) {
			this.formData.append('attachment', this.selectedFile, this.selectedFile.name);
		}

    this.formData.append('course-attachment', JSON.stringify(this.attachment));
    this.courseService.saveAttachment(this.formData)
                      .pipe(
                        take(1),
                        tap((success: boolean) => {
                          if (success) {
                            this.isEdit = false;
                            this.closeForm();
                            this.updateAttachments.emit();
                          }
                        })).subscribe();
  }

  closeForm(): void {
    this.attachmentForm.reset();
    this.closeAttachment.emit();
  }
}
