import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, inject, Input, SecurityContext, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'prism-course-view',
  templateUrl: './course-view.component.html',
  styleUrl: './course-view.component.scss'
})
export class CourseViewComponent {
  private sanitizer = inject(DomSanitizer);
  private http = inject(HttpClient);
  @Input() courseName?: string = 'course1';
  blobUrl?: SafeResourceUrl;
  @ViewChild('course-iframe') iframeRef?: ElementRef<HTMLIFrameElement>;

  ngOnInit(): void {
    this.blobUrl = this.sanitizer.bypassSecurityTrustResourceUrl(`assets/courses/course1/index.html`);
    // TODO: switch out once all courses are available
    // this.blobUrl = this.sanitizer.bypassSecurityTrustResourceUrl(`assets/courses/${this.courseName}/index.html`);
  }
}
