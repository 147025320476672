<nz-ribbon [nzText]="badgeText" [nzColor]="isCompleted ? '#20A056' : '#ebe655'">
    <div class="w-full shadow-lg rounded-lg overflow-hidden cursor-pointer">
      <div class="shadow-xl">
        @if (sasToken) {
            <img class="crop" alt="course image" [src]="blobImgUrl">
        }
        <div class="px-6 py-4 bg-light-purple">
          <p class="truncate font-bold text-dark-purple">{{ course.course.name }}</p>
          <p class="text-xs">
           By {{ course.course.author }}
          </p>
        </div>
      </div>
    </div>
</nz-ribbon>