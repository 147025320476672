import { Injectable, inject } from '@angular/core';
import { DataService } from '../../../../core/services/data.service';
import { Observable } from 'rxjs';
import { ICourseAttachment } from '../interface/icourse-attachment';
import { IUserCourses } from '@core/interfaces/iuser-courses';

@Injectable({
  providedIn: 'root'
})
export class CoursesService {
  dataService = inject(DataService);

  saveAttachment(attachment: FormData): Observable<boolean>  {
    return this.dataService.post('courseAttachment', attachment);
  }

  getAttachments(courseId: number): Observable<ICourseAttachment[]> {
    return this.dataService.get(`courseAttachment/${courseId}`);
  }

  getUserCourse(courseId: number): Observable<IUserCourses> {
    return this.dataService.get(`userCourse/course/${courseId}`);
  }

  completeCourse(courseId: number): Observable<boolean> {
    return this.dataService.post(`userCourse/complete/${courseId}`, {});
  }

  addUserCourse(courseId: number): Observable<boolean> {
    return this.dataService.post(`userCourse/add/${courseId}`, {});
  }

  downloadCertificate(courseId: number): Observable<any> {
    return this.dataService.postWithOptions(`userCourse/download-certificate/${courseId}`, {}, {
      responseType: 'blob'
    });
  }

  updateCourse(course: FormData): Observable<boolean> {
    return this.dataService.put('course', course);
  }

  deleteAttachment(attachmentId: number): Observable<boolean> {
    return this.dataService.delete(`courseAttachment/delete-attachment?id=${attachmentId}`);
  }
}
