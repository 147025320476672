export enum InputType {
  Text = 'text',
  Email = 'email',
  Textarea = 'textarea',
  Password = 'password',
  Select = 'select',
  Number = 'number',
  File = 'file',
  RichTextEditor = 'richTextEditor'
}
