<prism-select 
  [selectOptions]="options"
  [label]="label"
  placeHolder="Select Page Size"
  [control]="pageSizeControl"
  [required]="required"
  [readOnly]="readOnly"
  [allowDeselect]="false"
  [labelClass]="labelClass"
  (select)="emitPageSize($event)">
</prism-select>

