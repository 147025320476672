<prism-select 
  [selectOptions]="options"
  [label]="label"
  placeHolder="Select Role"
  [control]="control"
  [required]="required"
  [readOnly]="readOnly"
  [allowDeselect]="true"
  (onSelected)="emitRole($event)">
</prism-select>
