import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HelperService {
  public emailRegex =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

  public nameRegex = /^[a-zA-ZñÑàÀèÈ\s.'-]*$/;
}

export function getSearchUrl(baseUrl: string, params: any): string {
  return baseUrl.concat(
      Object.entries(params).map(([key, val]) => {
        if (val != null) {
          return `${key}=${val}`
        }
        return
      }).filter(Boolean).join('&')
    );
}

export function extractDomainFromUrl(url: string): string {
  try {
      const urlObject = new URL(url);
      return urlObject.hostname;
  } catch (error) {
      return '';
  }
}

