import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { AuthService } from '@core/services/auth.service';
import { Observable } from 'rxjs';

@Injectable()
export class LogInGuard implements CanActivate {
  constructor(private auth: AuthService, private router: Router) {}

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let isValid = false;
    const isUserLoggedIn = this.auth.checkLogin();

    if (!isUserLoggedIn) {
      return isValid = true;
    } 
    
    return this.router.parseUrl('dashboard');
  }
}
