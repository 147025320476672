import { Injectable, inject } from '@angular/core';
import { DataService } from '@core/services/data.service';
import { Observable } from 'rxjs';
import { IResource, IResourceRequest } from '../interfaces/iresource';
import { HttpHeaders } from '@angular/common/http';
import { IImpactVideoResponse } from '../interfaces/iimpact-video';

const fileOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'multiform/form-data' }),
};

@Injectable({
  providedIn: 'root'
})
export class ResourceService {
  dataService = inject(DataService);

  getResources(): Observable<IResource[]> {
    return this.dataService.get(`resource/all`);
  }

  getResource(id: number): Observable<IResource> {
    return this.dataService.get(`resource/${id}`);
  }

  addResource(payload: FormData): Observable<boolean> {
    return this.dataService.post(`resource`, payload);
  }

  addImpactVideo(payload: FormData): Observable<boolean> {
    return this.dataService.post('impactvideo', payload);
  }

  getAllImpactVideos(): Observable<IImpactVideoResponse[]> {
    return this.dataService.get(`impactvideo/all`);
  }

  getImpactVideo(id: number): Observable<any> {
    return this.dataService.get(`impactvideo/${id}`);
  }

  deleteResource(id: number): Observable<boolean> {
    return this.dataService.delete(`resource/delete-resource?id=${id}`);
  }

  deleteImpactVideo(id: number): Observable<boolean> {
    return this.dataService.delete(`impactvideo/delete-video?id=${id}`);
  }
}
