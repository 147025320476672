import { Injectable, WritableSignal, signal } from "@angular/core";
import { DataService } from "@core/services/data.service";
import { Observable } from "rxjs";
import { environment } from '@env/environment';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Router } from "@angular/router";
import { ILoginResponse } from "@core/interfaces/ilogin-response";
import { IForgotPwRequest, ILoginRequest } from "@core/interfaces/ilogin-request";
import { ToastService } from "./toast.service";
import { getSearchUrl } from "./helper.service";

@Injectable({
  providedIn: 'root'
})

export class AuthService {
  private readonly authToken = environment.authToken;
  private readonly userInfo = 'prism-user';
  private jwtHelper = new JwtHelperService();
  currentUserInfo: WritableSignal<ILoginResponse | null>  = signal(null);

  constructor(
    private dataService: DataService,
    private router: Router,
    private toast: ToastService) { this.loadUserInfo(); }

  login(payload: ILoginRequest, message?: string): Observable<any> {
    return this.dataService.post(`auth/login`, payload, message);
  }

  checkLogin(): boolean {
    let result = false;
    const token = localStorage.getItem(this.authToken);
    if (token) {
      result = !this.jwtHelper.isTokenExpired(token);
    }
    return result;
  }

  getCurrentUser(): any {
    return JSON.parse(window.localStorage.getItem(this.userInfo) ?? '{}');
  }

  private loadUserInfo(): void {
    const storedUserInfo = localStorage.getItem(this.userInfo);
    const userInfo = storedUserInfo ? JSON.parse(storedUserInfo) : null;
    this.currentUserInfo.set(userInfo);
  }

  captureUserInfo(loginRes: ILoginResponse): void {
    localStorage.setItem(this.authToken, JSON.stringify(loginRes.token));
    localStorage.setItem(this.userInfo, JSON.stringify(loginRes));
    const storedUserInfo = localStorage.getItem(this.userInfo);
    const userInfo = storedUserInfo ? JSON.parse(storedUserInfo) : null;
    this.currentUserInfo.set(userInfo);
  }

  logout(): void {
    localStorage.clear();
    this.currentUserInfo.set(null);
    this.router.navigateByUrl('/');
    this.toast.success('Logged out successfully');
  }

  forgotPassword(payload: IForgotPwRequest, message?: string): Observable<boolean> {
    return this.dataService.post(`auth/forgot-password`, payload, message);
  }

  isValidIdentifier(guid: string, message?: string): Observable<boolean> {
    return this.dataService.get(`auth/is-valid-identifier/${guid}`, message);
  }

  changePassword(payload: any, message?: string): Observable<boolean> {
    return this.dataService.put(`auth/password?password=${payload.password}&guid=${payload.guid}`, payload, message);
  }

  createPassword(params: any, message?: string): Observable<boolean> {
    const url = getSearchUrl('auth/create-password?', params);
    return this.dataService.put(url, message);
  }

  siteVerify(token: string): Observable<boolean> {
    return this.dataService.post('auth/captcha', { token: token});
  }

  getStorageSasToken(container: string, message?: string): Observable<string> {
    return this.dataService.get(`auth/blobstorage-sas-token/${container}`, message);
  }
}
