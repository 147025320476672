export const environment = {
  production: false,
  apiUrl: 'https://devapi.prismcalifornia.org/',
  url: 'https://dev.prismcalifornia.org/',
  authToken: 'prism-token',
  storageName: "prismdevstorage",
  storageUrl: 'storageUrl',
  encryptionKey: "4a25f88fcac1b07e93a6dba85e3018e7",
  recaptcha: {
    siteKey: '6LeJa28pAAAAABobnV8yAVYQ81TvqIPBWW21mQOy',
  },
  uploadFileLink: 'https://prismdevstorage.blob.core.windows.net/resources/user/Prism-BulkUserUploadTemplate.xlsx'
};
