import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { InputType } from '@core/enums/input-type';
import { EditorChangeContent, EditorChangeSelection } from 'ngx-quill';
import Quill from 'quill';

@Component({
  selector: 'prism-input',
  templateUrl: './input.component.html',
  styleUrl: './input.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class InputComponent {
  @Output() search = new EventEmitter();
  @Output() fileChange = new EventEmitter();
  @Output() textChange = new EventEmitter();
  @Input() inputType: string = 'text';
  @Input() control!: FormControl | any;
  @Input() label: string = '';
  @Input() required: boolean = false;
  @Input() placeHolder: string = 'Select an option';
  @Input() hasSearch: boolean = false;
  @Input() readOnly?: boolean = false;
  @Input() labelColor: string = 'text-black';
  @Input() acceptFileType: string = 'image/*';
  @Input() maxLength: number = 100;
  @Input() identifier?: string; 
  InputType = InputType;

  quillConfiguration = {
    toolbar: '#toolbar'
  }

  searchInput(value: string): void {
    this.search.emit(value);
  }

  file(file: any): void {
    this.fileChange.emit({file: file, id: file.target.id});
  }

  onContentChanged(event: any) {
    this.textChange.emit(event);
  }
}
