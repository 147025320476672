import { Component, Input, Output, EventEmitter } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'prism-card',
  templateUrl: './card.component.html',
  styleUrl: './card.component.scss'
})
export class CardComponent {
  @Input() entity: any;
  @Input() title: string = '';
  @Input() author: string = '';
  @Input() footer: string = '';
  @Input() img: string = '';
  @Output() cardClick: EventEmitter<any> = new EventEmitter();
  @Input() sasToken?: string = '';
  @Input() url?: string = '';
  @Input() isCourseCard: boolean = false;
  blobImgUrl: SafeResourceUrl = '';

  constructor(private sanitizer: DomSanitizer) { }
  
  ngOnChanges() {
    if (this.sasToken) {
      this.generateUrlWithSasToken(this.url);
    }
  }
  
  generateUrlWithSasToken(blobUrl?: string) {
    const urlWithSasToken = `${blobUrl}${this.sasToken}`;
    this.blobImgUrl = this.sanitizer.bypassSecurityTrustResourceUrl(urlWithSasToken);
  }

  view() {
    this.cardClick.emit(this.entity);
  }
}
