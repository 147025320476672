import { Injectable, inject } from '@angular/core';
import { IUser, IUserBulk } from '@core/interfaces/iuser';
import { DataService } from '@core/services/data.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserManagementService {
  dataService = inject(DataService);

  getUsers(payload: any) {
    return this.dataService.post(`user/search`, payload);
  }

  addUser(payload: IUser) {
    return this.dataService.post(`user/add-user`, payload);
  }

  addUsers(payload: IUserBulk[]) {
    return this.dataService.post(`user/add-users`, payload);
  }

  editUser(payload: IUser, message?: string) {
    return this.dataService.post(`user/edit-user`, payload, message);
  }

  deleteUser(id: number) {
    return this.dataService.delete(`user/delete-user?id=${id}`);
  }

  isDuplicateEmail(email: string,  message?: string): Observable<boolean> {
    return this.dataService.get(`user/duplicate-email?email=${email}`, message, false);
  }
}
