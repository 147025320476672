<div class="w-full h-full shadow-lg rounded-lg overflow-hidden cursor-pointer">
  <div class="shadow-xl h-full">
    @if (sasToken) {
      <img [src]="blobImgUrl" alt="Placeholder" class="w-full h-44" (click)="view()"/>
    }
    @else {
      <div class="crop" alt="background image" style="background-image: url({{img}});"></div>
    }
     <div class="px-6 h-full py-4 bg-light-purple">
        <div class="font-bold text-dark-purple">{{ title }}</div>
        <p class="text-xs py-0.5">
          <b>By</b> 
          {{ author }}
        </p>
        <p class="text-xs py-0.5">
         {{ footer }}
        </p>
      </div>
  </div>
</div>
