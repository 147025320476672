import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Role } from '@core/enums/roles';
import { IRoles } from '@core/interfaces/ilookup';
import { AuthService } from '@core/services/auth.service';
import { LookupService } from '@core/services/lookup.service';
import { tap } from 'rxjs';

@Component({
  selector: 'prism-select-role',
  templateUrl: './select-role.component.html',
  styleUrl: './select-role.component.scss'
})
export class SelectRoleComponent {
  private authService = inject(AuthService);
  private lookupService = inject(LookupService);
  @Output() role = new EventEmitter<any>();
  @Input() selectedRole?: number | null;
  @Input() control!: FormControl | any;
  @Input() label: string = '';
  @Input() required: boolean = false;
  @Input() readOnly?: boolean = false;
  options: IRoles[] = [];
  currecntUser = this.authService.getCurrentUser();

  ngOnInit(): void {
    this.getRoles();
  }

  getRoles(): void {
    this.lookupService.getRoles()
    .pipe(
      tap((res: IRoles[]) => {
        if (this.currecntUser.roleId === Role.DistrictAdmin) {
          this.options = res.filter(role => role.id >= Role.DistrictAdmin);
        } else {
          this.options = res;
        }
      })
    )
    .subscribe();
  }

  emitRole(role: any): void {
    this.role.emit(role);
  }
}
