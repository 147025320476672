import { Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'prism-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SelectComponent implements OnInit {
  @ViewChild('select', { read: ElementRef }) selectRef!: ElementRef;
  @Output() select = new EventEmitter();
  @Input() control!: FormControl | any;
  @Input() label: string = '';
  @Input() required: boolean = false;
  @Input() selectOptions: any[] = [];
  @Input() placeHolder: string = 'Select an option';
  @Input() hasSearch: boolean = false;
  @Input() readOnly?: boolean = false;
  @Input() labelClass: string = '';
  @Input() allowDeselect: boolean = false;
  @Input() ariaSelector: string = 'input.ant-select-selection-search-input';
  @Input() ariaLabel: string = 'Search for option';
  @Input() initialId: number = 1;

  optionGroups: { label: string; options: any[] }[] = [];

  ngOnInit(): void {
    this.initOptionGroups();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.initOptionGroups();
  }

  selectOption(option: any): void {
    if (option === null) {
      this.control.setValue(null);
    } else {
      this.control.setValue(option);
      this.select.emit(option);
    }
  }

  searchOptions(searchText: string): void {
    const filteredOptions = this.selectOptions.filter((option) =>
      option.name.toLowerCase().includes(searchText.toLowerCase())
    );
    this.optionGroups = [
      {
        label: 'Filtered Options',
        options: filteredOptions,
      },
    ];
  }

  private initOptionGroups(): void {
    this.optionGroups = [
      {
        label: 'All Options',
        options: this.selectOptions,
      },
    ];
  }

}