<div class="my-4 px-20">
  <prism-title additionalClasses="text-4xl" [title]="'Edit ' + course.name"></prism-title>
    <p class="text-2xl font-bold pb-4">Course Details</p>
      <form [formGroup]="editCourseForm" class="flex flex-col gap-4 mb-4">
        <prism-input
          [control]="editCourseForm.get('name')"
          label="Course Name"
          placeHolder="Enter course name"
          [required]="true">
        </prism-input>
        <prism-input
          [control]="editCourseForm.get('description')"
          label="Description"
          placeHolder="Enter description"
          [inputType]="InputType.Textarea"
          [maxLength]="1000"
          [required]="true">
        </prism-input>
        <prism-input
          [control]="editCourseForm.get('thumbnail')"
          label="Thumbnail"
          placeHolder="Enter thumbnail"
          [inputType]="InputType.File"
          [required]="true"
          identifier="thumbnail"
          (fileChange)="fileChange($event)">
        </prism-input>
        <p class="font-bold text-2xl">Attachments</p> 
        @if (attachments?.length > 0) {
          <p class="font-bold text-xl">Attached Files</p>
          @for (attachment of attachments; track $index) {
            <div class="grid grid-cols-2 gap-x-4">
              <label 
                id="attachment-label"
                aria-labelledby="attachment-label" 
                class="sr-only">
                Attachment
              </label>
              <input type="text"
                id="attachment"
                aria-labelledby="attachment-label"
                [value]="attachment.fileName"
                readonly
                class="w-full text-[16px] leading-6 px-4 border border-dark-purple rounded-lg">
              <input
                type="file"
                aria-labelledby="file-input-label"
                hidden/>
              <prism-btn 
                type="button" 
                id="file-input-label" 
                aria-labelledby="file-input-label" 
                btnClass="h-full bg-white text-dark-purple font-bold border-2 rounded-xl border-dark-purple"
                (click)="deleteAttachment(attachment.id)">
                <i class="uil uil-trash-alt text-lg"></i>
                <span class="text-lg px-3 py-8">Delete Attachment</span>
              </prism-btn>
            </div>
          }
        }
        <prism-btn 
          btnClass="md:mt-2 bg-white text-dark-purple font-bold border-2 p-0 m-0 rounded-xl border-dark-purple"
          (click)="toggleAddAttachment()">
          <i class="uil uil-plus text-dark-purple font-bold pr-1"></i>
            Add Attachment
        </prism-btn>
        @if (addAttachment) {
          <prism-course-attachment 
            [courseId]="course.id" 
            [isEdit]="isEdit" 
            (closeAttachment)="toggleAddAttachment()" 
            (updateAttachments)="updateAttachments()">
          </prism-course-attachment>
        }
        <div class="flex flex-wrap gap-4">
          <prism-btn btnClass="btn-purple" (click)="closeForm()">
            Cancel
          </prism-btn>
          <prism-btn btnClass="btn-purple" [disabled]="!editCourseForm.valid" (click)="editCourse()">
            Save and Edit Course
          </prism-btn>
        </div>
    </form>
</div>