import { Component, OnInit, Signal, ViewEncapsulation, effect } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { InputType } from '@core/enums/input-type';
import { EncryptionService } from '@core/services/encryption.service';
import { AuthService } from '@core/services/auth.service';
import { Router } from '@angular/router';
import { IForgotPwRequest, ILoginRequest } from '@core/interfaces/ilogin-request';
import { ILoginResponse } from '@core/interfaces/ilogin-response';
import { Observable, concatMap, map, of, take } from 'rxjs';
import { HeaderModalType } from '@core/enums/header-modals';
import { ReCaptchaV3Service } from 'ng-recaptcha';
@Component({
  selector: 'prism-header',
  standalone: false,
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class HeaderComponent implements OnInit {
  currentUser: Signal<ILoginResponse | null> = this.authService.currentUserInfo;
  logoUrl = '../../../assets/images/prism-logo-type-black.svg';
  isLoginVisible: boolean = false;
  isForgotPwVisible: boolean = false;
  successModal: boolean = false;
  loginForm: FormGroup | any;
  forgotPwForm: FormGroup | any;
  InputType = InputType;
  HeaderModalType = HeaderModalType;
  iconImgUrl: string = 'assets/images/logged-in-user-icon.svg';
  openSideMenu: boolean = false;
  isProcessing: boolean = false;
  constructor(
    public modal: NzModalService,
    private fb: FormBuilder,
    private encryptionService: EncryptionService,
    private authService: AuthService,
    private recaptchaV3: ReCaptchaV3Service,
    private router: Router) {
    effect (() => {
      this.iconImgUrl = this.currentUser() ?
        'assets/images/Icon feather-user.svg' :
        'assets/images/login icon.svg';
    })
  }

  verifyAndSubmit$!: Observable<any>;

  get emailAddress() { return this.loginForm.get('emailAddress'); }
  get password() { return this.loginForm.get('password'); }
  get forgotPwEmailAddress() { return this.forgotPwForm.get('emailAddress'); }

  ngOnInit(): void {
    this.loginForm = this.fb.group({
      emailAddress: ['', [Validators.required, Validators.maxLength(50)]],
      password: ['', [Validators.required, Validators.maxLength(50)]],
    });
    this.forgotPwForm = this.fb.group({
      emailAddress: ['', [Validators.required, Validators.maxLength(50)]]
    });
  }

  toggleModal(modalType: string): void {
    this.isLoginVisible = modalType === HeaderModalType.Login;
    this.isForgotPwVisible = modalType === HeaderModalType.ForgotPw;
    this.successModal = modalType === HeaderModalType.ForgotPwConfirmation;
  }

  handleCancel(): void {
    this.isLoginVisible = false;
    this.isForgotPwVisible = false;
    this.successModal = false;
  }

  submit(): void {
    let loginPayload: ILoginRequest = this.loginForm.value;
    loginPayload.password = this.encryptionService.encryptPassword(this.password.value);

    this.verifyAndSubmit$ = this.recaptchaV3.execute('handleSubmit').pipe(
      concatMap((token: string) =>
        this.authService.siteVerify(token).pipe(
          concatMap((valid: boolean) => valid ?
          this.authService.login(this.loginForm.value, "Login Successful").pipe(
              map((res: ILoginResponse) => res)
            ) : of(valid)
          )
        )
      )
    );

    this.verifyAndSubmit$.pipe(take(1)).subscribe((res: ILoginResponse) => {
      if (res) {
        this.isLoginVisible = false;
        this.authService.captureUserInfo(res);
        this.router.navigateByUrl('dashboard');
      }
    });
  }

  submitForgotPw(): void {
    let payload: IForgotPwRequest = {
      emailAddress: this.forgotPwEmailAddress.value
    }
    this.verifyAndSubmit$ = this.recaptchaV3.execute('handleSubmit').pipe(
      concatMap((token: string) =>
        this.authService.siteVerify(token).pipe(
          concatMap((valid: boolean) => valid ?
          this.authService.forgotPassword(payload, 'Please check your email for reset password instructions.')
      .pipe(
              map((success: boolean) => success)
            ) : of(valid)
          )
        )
      )
    );
    this.isProcessing = true;
    this.verifyAndSubmit$.pipe(take(1)).subscribe((success: boolean) => {
        if (success) {
          this.isForgotPwVisible = false;
          this.successModal = true;
          this.forgotPwForm.reset();
          this.isProcessing = false;
        }
    });
  }

  logout(): void {
    this.authService.logout();
  }

  click() {
    this.openSideMenu = !this.openSideMenu;
  }
}
