<div class="px-20">
  <div class="md:flex md:flex-row justify-between mt-4">
    <prism-title title="External Resources"></prism-title>
    @if (canAdd) {
      <prism-btn 
        btnClass="md:mt-2 bg-white text-dark-purple font-bold border-2 p-0 m-0 rounded-xl border-dark-purple"
        (click)="goToResourcesForm()">
        <i class="uil uil-plus text-dark-purple font-bold pr-1"></i
          >Add Resource
      </prism-btn>
    }
  </div>
  <div class="my-4">
    <p>This is just a placeholder. This page contains resources that we endorse that are sourced
       from other institutions. This is not a part of the CaPSPD system.<br>These resources may
       come with fees.
    </p>
    <div class="grid md:grid-cols-2 lg:grid-cols-4 gap-x-4 gap-y-12 mt-4 mb-16">
      <prism-card *ngFor="let res of resources"
        [title]="res.title"
        [author]="res.createdByName"
        [sasToken]="sasToken"
        [url]="res.imageLink"
        (click)="viewResource(res.id)">
      </prism-card>
    </div>
  </div>
</div>
