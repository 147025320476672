import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[prismAddAriaLabel]'
})
export class AddAriaLabelDirective {
  @Input() querySelector?: string;
  @Input() ariaLabel?: string;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngAfterViewInit(): void {
    const selectElement = this.el.nativeElement;

    if (selectElement && this.querySelector) {
      const inputElement = selectElement.querySelector(this.querySelector);
      if (inputElement && this.ariaLabel) {
        this.renderer.setAttribute(inputElement, 'aria-label', this.ariaLabel);
      } else {
        console.error('Input element not found');
      }
    }
  }
}
