import { Component, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ICourseAttachment } from '../interface/icourse-attachment';
import { InputType } from '@core/enums/input-type';
import { ToastService } from '@core/services/toast.service';
import { switchMap, take, tap } from 'rxjs';
import { CoursesService } from '../services/courses.service';
import { AuthService } from '@core/services/auth.service';
import { DomSanitizer } from '@angular/platform-browser';
import { NzModalService } from 'ng-zorro-antd/modal';
import { FileInput } from '@core/enums/file-input';

@Component({
  selector: 'prism-course-edit',
  templateUrl: './course-edit.component.html',
  styleUrl: './course-edit.component.scss'
})
export class CourseEditComponent implements OnInit {
  @Input() course: any;
  @Input() attachments?: any;
  @Output() isEditChange = new EventEmitter<boolean>();
  @Output() updateCourseAttachments = new EventEmitter();
  private fb = inject(FormBuilder);
  private toastService = inject(ToastService);
  private courseService = inject(CoursesService);
  private authService = inject(AuthService);
  private sanitizer = inject(DomSanitizer);
  private modal= inject(NzModalService);
  editCourseForm: FormGroup | any;
  InputType = InputType;
  addAttachment: boolean = false;
  isEdit: boolean = false;
  selectedFile: any;
  formData: FormData | null = null;
  attachmentSasToken: string = '';

  ngOnInit(): void {
    this.editCourseForm = this.fb.group({
      id: [0],
      name: [null, Validators.required],
      description: [null, Validators.required],
      thumbnail: [null, Validators.required],
    });
    this.editCourseForm.patchValue(this.course);
  }

  editCourse(): void {
    if (this.editCourseForm.invalid) {
      this.toastService.error('Please fill out all required fields');
      return;
    }
    
    Object.assign(this.course, this.editCourseForm.value);
    this.formData = new FormData();

    if (this.selectedFile) {
        this.formData.append('imageFile', this.selectedFile, this.selectedFile.name);
    }

    this.formData.append('course', JSON.stringify(this.course));

    this.courseService.updateCourse(this.formData).pipe(take(1)).subscribe((success: boolean) => {
        if (success) {
          this.toastService.success('Course updated successfully');
          this.isEditChange.emit(false);
        }
    });
  }

  deleteAttachment(attachmentId: number): void {
    this.modal.confirm({
      nzTitle: `Delete Attachment`,
      nzContent: `Are you sure you want to delete this attachment?`,
      nzOkText: 'Yes',
      nzIconType: "exclamation-circle",
      nzOnOk: () => {
        this.courseService.deleteAttachment(attachmentId).pipe(take(1)).subscribe((success: boolean) => {
          if (success) {
            this.toastService.success('Attachment deleted successfully');
            this.attachments = this.attachments.filter((attachment: any) => attachment.id !== attachmentId);
            this.updateCourseAttachments.emit();
          }});
        },
      nzCancelText: 'No',
    });
  }

  toggleAddAttachment(): void {
    this.addAttachment = !this.addAttachment;
  }

  closeForm(): void {
    this.isEditChange.emit(false);
  }
  
  fileChange(event: any): void {
   if (event.id === FileInput.Thumbnail) {
    const fileList: FileList = event.file.target.files;
    if (fileList.length > 0) {
      const chosenFile: File = fileList[0];
      this.selectedFile = chosenFile;
      this.editCourseForm.get('thumbnail').markAsDirty();
      this.editCourseForm.patchValue({
        thumbnail: chosenFile.name,
      });
    }}
  }
  
  updateAttachments() {
    this.authService.getStorageSasToken('course-attachments').pipe(
      take(1),
      tap((res: any) => this.attachmentSasToken = res.value),
      switchMap(() => this.courseService.getAttachments(this.course.id).pipe(take(1))),
      tap((attachments: ICourseAttachment[]) => {
        this.attachments = attachments.map((attachment: ICourseAttachment) => { 
          attachment.blobUrlSas = this.generateUrlWithSasToken(attachment.blobUrl, this.attachmentSasToken);
          return attachment;
        });
        this.updateCourseAttachments.emit();
      })).subscribe();
    }

    generateUrlWithSasToken(blobUrl?: string, sasToken?: string) {
      if (sasToken && blobUrl) {
        const urlWithSasToken = `${blobUrl}${sasToken}`;
        return this.sanitizer.bypassSecurityTrustResourceUrl(urlWithSasToken);
      }
      return '';
    }
}
