import { Component, Input } from '@angular/core';

@Component({
  selector: 'prism-title',
  templateUrl: './title.component.html',
  styleUrl: './title.component.scss'
})
export class TitleComponent {
  @Input() title?: string;
  @Input() additionalClasses?: string = 'text-3xl';

}
