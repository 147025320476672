<form [formGroup]="attachmentForm"
  class="flex flex-col gap-4 py-3"
  (ngSubmit)="submitResource()">
  <div class="col-span-2">
    <prism-input
    label="Add Atachment"
    [control]="attachmentForm.get('fileName')"
    [inputType]="InputType.File"
    identifier="attachment"
    (fileChange)="fileChange($event)"
    acceptFileType="image/*, .pdf, .xls, .xlsx, .csv">
    </prism-input>
  </div>
  <prism-btn btnClass="btn-purple" [disabled]="!attachmentForm.valid">
    Save and Add Attachment
  </prism-btn>
</form>
