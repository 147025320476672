import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Action } from './enums/action';
import { IAction } from './interface/iaction';

@Component({
  selector: 'prism-table',
  templateUrl: './table.component.html',
  styleUrl: './table.component.scss',
  encapsulation: ViewEncapsulation.None 
})
export class TableComponent implements OnInit {
  @Output() pageIndexChange = new EventEmitter();
  @Output() pageSizeChange = new EventEmitter();
  @Output() add = new EventEmitter();
  @Output() edit = new EventEmitter();
  @Output() delete = new EventEmitter();
  @Input() columns: string[] = [];
  @Input() colHeaders: any;
  @Input() tableData: any[] = []
  @Input() isDelete = false;
  @Input() isEdit = false;
  @Input() pageSize = 10;
  @Input() pageIndex = 1;
  @Input() totalData = 0;
  actions: IAction[] = [];
  actionType = Action;
  
  ngOnInit(): void {
    this.actions = [
      { label: 'Delete', 
        isActive: this.isEdit, 
        icon: 'uil uil-times-circle text-red', 
        handler: this.action.bind(this), 
        type: this.actionType.delete },

      { label: 'Edit', 
        isActive: this.isEdit, 
        icon: 'uil uil-edit text-dark-purple', 
        handler: this.action.bind(this), 
        type: this.actionType.edit },
    ];
  }

  action(item: any, actionType: string) {
    switch (actionType) {
      case Action.edit:
        this.edit.emit(item);
        break;
      case Action.delete:
        this.delete.emit(item);
        break;
      case Action.add:
        this.add.emit(item);
        break;
      default:
        break;
    }
  }

  handlePageIndexChange(pageIndex: number): void {
    this.pageIndex = pageIndex;
    this.pageIndexChange.emit(pageIndex);
  }

  handlePageSizeChange(pageSize: number): void {
    this.pageSize = pageSize;
    this.pageSizeChange.emit(pageSize);
  }
}
