import { Injectable } from '@angular/core';
import { ToastComponent } from '@shared/components/toast/toast.component';
import { BehaviorSubject } from 'rxjs';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzNotificationPlacement, NzNotificationService } from 'ng-zorro-antd/notification';


@Injectable({
  providedIn: 'root'
})
export class ToastService {

  responseMessage: string = '';
  $responseMessage = new BehaviorSubject<any>(this.responseMessage);
  toastConfig: NzMessageModule = {
    nzDuration: 4000,
    nzPauseOnHover: true,
    nzAnimate: true,
    nzPlacement: 'bottomRight',
  }
  placement = 'topRight';

  constructor(
    private message: NzMessageService,
    private notification: NzNotificationService
    ) { }

    success(message: string) {
      this.$responseMessage.next(message);
      this.notification.success('Success!', message, this.toastConfig);
    }

  warn(message: string) {
    this.$responseMessage.next(message);
    this.notification.warning('Warning', message, this.toastConfig);  }

  error(message: string) {
    this.$responseMessage.next(message);
    this.notification.error('Error', message, this.toastConfig);
  }

  info(message: string) {
    this.$responseMessage.next(message);
    this.notification.info('Info', message, this.toastConfig);
  }
}
