import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'prism-select-page-size',
  templateUrl: './select-page-size.component.html',
  styleUrl: './select-page-size.component.scss'
})
export class SelectPageSizeComponent {
  @Output() pageSize = new EventEmitter<any>();
  @Input() selectedOrganization?: number | null;
  @Input() label: string = '';
  @Input() required: boolean = false;
  @Input() readOnly?: boolean = false;
  @Input() labelClass: string = '';

  pageSizeControl = new FormControl(1);
  options: any = [
    { id: 1, value: 10, label: '10', name: '10/page'},
    { id: 2, value: 25, label: '25', name: '25/page'},
    { id: 3, value: 50, label: '50', name: '50/page'},
    { id: 4, value: 100, label: '100', name: '100/page'},
  ];

  ngOnInit(): void {
    this.pageSizeControl.valueChanges.subscribe((value) => {
      const pageSize = this.options.find((option: any) => option.id === value);
      this.emitPageSize(pageSize);
    });
  }

  emitPageSize(pageSize: any): void {
    this.pageSize.emit(pageSize.value);
  }
}
