import { Component, Input, Output, EventEmitter, inject, ViewEncapsulation } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AuthService } from '@core/services/auth.service';
import { ICourse } from '@views/features/courses/interfaces/icourse';
import { IUserCourse } from '@views/features/courses/interfaces/iuser-course';
import { take, tap } from 'rxjs';

@Component({
  selector: 'prism-course-card',
  templateUrl: './course-card.component.html',
  styleUrl: './course-card.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class CourseCardComponent {
  private authService = inject(AuthService);
  @Input() course!: IUserCourse;
  @Output() cardClick: EventEmitter<any> = new EventEmitter();
  sasToken: string = ''
  blobImgUrl: SafeResourceUrl = '';
  badgeText: string = '';
  isCompleted: boolean = false;
  private resourceContainer = 'courses';
  constructor(private sanitizer: DomSanitizer) { }
  
  ngOnChanges() {
    this.getSasToken();
    this.generateBadgeText(this.course);
  }

  getSasToken(): void {this.authService.getStorageSasToken(this.resourceContainer).pipe(
    take(1),
    tap((res: any) => {
        this.sasToken = res.value;
        this.generateUrlWithSasToken(this.course.course.thumbnail);
    })
    ).subscribe();
  }

  generateUrlWithSasToken(blobUrl?: string) {
    const urlWithSasToken = `${blobUrl}${this.sasToken}`;
    this.blobImgUrl = this.sanitizer.bypassSecurityTrustResourceUrl(urlWithSasToken);
  }

  generateBadgeText(course: IUserCourse) {
    if (course.isCompleted) {
      this.badgeText = 'Completed';
      this.isCompleted = true;
    }
    else this.badgeText = 'In progress';
  }
}