import { Component } from '@angular/core';
import { ToastService } from '@core/services/toast.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Subscription } from 'rxjs';
import { NzNotificationPlacement, NzNotificationService } from 'ng-zorro-antd/notification';

@Component({
  selector: 'prism-toast',
  templateUrl: './toast.component.html',
  styleUrl: './toast.component.scss'
})
export class ToastComponent {
  subscription: Subscription | any;
  message?: string;

  constructor(private toastService: ToastService, private messages: NzMessageService, private notification: NzNotificationService) { }

  ngOnInit() {
    this.subscription = this.toastService.$responseMessage.subscribe({
      next: (msg: string) => {
        if (msg) {
          this.message = msg;
          this.notification.create('success', `This is a message of success`, 'test');
        }
      }
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}
