<div id="navbar" class="w-full hidden lg:block mx-auto px-20 py-2 bg-[#FBFBFB] ">
  <div class="flex justify-between">
    <ul class="flex gap-3 text-base">
        <li *ngFor="let menuItem of navbarItems" class="text-black mr-6">
          @if (menuItem.label !== Navbar.Resources) {
            <a [href]="menuItem.link" class="hover:text-gray-400 font-semibold" [ngClass]="{ 'underline !decoration-solid !decoration-gray-400 decoration-3 text-dark-purple': isActive(menuItem.link) }">{{ menuItem.label }}</a>
          }
          @else {
            <nz-dropdown>
              <a nz-dropdown [nzDropdownMenu]="menu" class="font-bold" [ngClass]="{ 'underline !decoration-solid !decoration-gray-400 decoration-3 text-dark-purple': isActive(menuItem.link) }">
                Resources
                <i class="uil uil-angle-down text-md text-dark-purple"></i>
              </a>            
              <nz-dropdown-menu #menu="nzDropdownMenu">
                <ul nz-menu nzSelectable>
                  <li nz-menu-item (click)="navigate('resources/external')">External Resources</li>
                  <li nz-menu-item (click)="navigate('resources/impact-video')">Impact Video</li>
                </ul>
              </nz-dropdown-menu>
            </nz-dropdown>
          }
        </li>
    </ul>
    <div class="text-black" ng-container>
      @if (!isLoggedIn || currentUser()?.roleId == role.User) {
        <a href="/contact-us" class="hover:text-gray-400 font-semibold" [ngClass]="{ 'underline !decoration-solid !decoration-gray-400 decoration-3 text-dark-purple': isActive('/contact-us') }">Contact Us</a>
      }
    </div>
  </div>
</div>
<nz-layout *ngIf="openSideMenu" class="fixed z-[200]">
  <nz-sider nzWidth="200px">
      <div class="flex justify-center">
          <a class="m-4" href="/home" [ngClass]="{ 'underline !decoration-solid !decoration-gray-400 decoration-3 text-gray-400': isActive('/home') }">
              <img class="w-20" [src]="logoUrl" alt="Prism Logo">
          </a>
      </div>
      <ul nz-menu nzMode="inline" [nzInlineIndent]="1">
        <div class="flex flex-row flex-wrap place-items-start">
          <img [src]="iconImgUrl" class="w-6 ml-4 mr-2 mt-2" alt="User Icon">
          <div>
            @if (!isLoggedIn) {
              <a class="font-bold flex ml-4 my-6" (click)="onLoginClick()">Log in</a>
            }
            @else {
              <li class="mt-4"
                nz-submenu 
                [nzTitle]="userFullName">
                <ul class="hover:text-gray-400">
                  <li nz-menu-item><a href="/course-history">Course History</a></li>
                  <li nz-menu-item><a  href="/account-settings">Account Settings</a></li>
                </ul>
              </li>
            }
          </div>
        </div>
        <div *ngFor="let menuItem of navbarItems">
          @if (menuItem.label !== Navbar.Resources) {
            <li nz-menu-item>
              <a [href]="menuItem.link" class="hover:text-gray-400 font-semibold ml-2" [ngClass]="{ 'underline !decoration-solid !decoration-dark-purple decoration-3 text-dark-purple': isActive(menuItem.link) }">{{ menuItem.label }}</a>
            </li>
          }
          @else {
            <li class="hover:text-gray-400 font-semibold ml-2"
              nz-submenu 
              [nzTitle]="menuItem.label">
              <ul class="hover:text-gray-400 ml-2">
                <li nz-menu-item><a href="resources/external" class="font-normal">External Resources</a></li>
                <li nz-menu-item><a  href="resources/impact-video" class="font-normal">Imapact Videos</a></li>
              </ul>
            </li>
          }
        </div>
        @if (!isLoggedIn || currentUser()?.roleId == role.User) {
          <li nz-menu-item class="hover:text-gray-400 font-semibold ml-2">
            <a href="/contact-us" [ngClass]="{ 'underline !decoration-solid !decoration-blue-800 decoration-3 text-dark-purple': isActive('/contact-us') }">Contact Us</a>
          </li>
        }
        @if(isLoggedIn) {
          <li nz-menu-item class="hover:text-gray-400 font-semibold ml-2">
            <a (click)="logout()">Log Out</a>
          </li>
        }
      </ul>
  </nz-sider>
</nz-layout>